import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-assistments',
  templateUrl: './assistments.component.html',
  styleUrls: ['./assistments.component.css']
})
export class AssistmentsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
